import { IonCardSubtitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Portfolio from '../components/Portfolio';
import './PortfolioTab.css';

const PortfolioTab: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>I'm Johanna, <br /><IonCardSubtitle>Hobby Photographer & Content Creator</IonCardSubtitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Portfolio />
      </IonContent>
    </IonPage>
  )
}

export default PortfolioTab;
