import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { imagesOutline, logoInstagram, logoYoutube, mailOutline, storefrontOutline } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';

import PortfolioTab from './pages/PortfolioTab';
import GalleryPage from './pages/GalleryPage';
import GalleriesContextProvider from './data/GalleriesContextProvider';

import { Browser } from '@capacitor/browser';
import ContactPage from './pages/ContactPage';

setupIonicReact();

const App: React.FC = () => {

  const openCapacitorSite = async (browserURL: string) => {
    await Browser.open({ url: browserURL });
  }

  return (
    <IonApp><GalleriesContextProvider>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
          <Route path="/portfolio/gallery/:galleryID">
              <GalleryPage />
            </Route>
            <Route exact path="/portfolio">
              <PortfolioTab />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            
            <Route exact path="/">
              <Redirect to="/portfolio" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="portfolio" href="/portfolio">
              <IonIcon icon={imagesOutline} />
              <IonLabel>Portfolio</IonLabel>
            </IonTabButton>
            <IonTabButton tab="instagram" onClick={() => openCapacitorSite("https://www.instagram.com/the_world_of_kalle/")}>
              <IonIcon icon={logoInstagram} />
              <IonLabel>Instagram</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Etsy" onClick={() => openCapacitorSite("https://www.etsy.com/de/shop/TheTinyKnots")}>
              <IonIcon icon={storefrontOutline}  />
              <IonLabel>Etsy</IonLabel>
            </IonTabButton>
            <IonTabButton tab="YouTube" onClick={() => openCapacitorSite("https://www.youtube.com/@vantagepointofnature")}>
              <IonIcon icon={logoYoutube}  />
              <IonLabel>YouTube</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Contact"  href="/contact">
              <IonIcon icon={mailOutline}  />
              <IonLabel>Contact</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter></GalleriesContextProvider>
    </IonApp>
  )
};

export default App;
