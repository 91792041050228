import { IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import GalleriesContext, { GalleryInterface, Media } from '../data/galleries-context';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

import './GalleryPage.css';
import VideoModal from '../components/VideoModal';
import ProgressiveImg from '../components/ProgressiveImg';

const GalleryPage: React.FC = () => {
  const { galleryID } = useParams<any>();
  const galleriesContext = useContext(GalleriesContext);
  const [myVideoModal, setMyVideoModal] = useState<{ isOpen: boolean, currentVideo: string }>({ isOpen: false, currentVideo: '' })


  const filterGalleries = (galleries: GalleryInterface[], galleryID: number) => {
    const singleGallery = galleries.filter(function (this: number, el) {
      // eslint-disable-next-line
      return el.galleryId == this
    }, galleryID);

    return singleGallery[0];
  }

  const filterMedia = (media: Media[], type: String) => {
    let isVideo = false;
    if (type === "Videos") isVideo = true;
    const filteredMedia = media.filter(function (this: number, el) {
      return el.isVideo === isVideo
    });
    return filteredMedia;
  }


  const gallery = filterGalleries(galleriesContext.galleries, galleryID);

  const images = filterMedia(gallery.media, "Images");
  const videos = filterMedia(gallery.media, "Videos");

  return (
    <IonPage>
      <IonHeader>

        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton default-href="#"></IonBackButton>
          </IonButtons>
          <IonTitle>{gallery.name}</IonTitle>
        </IonToolbar>

      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <Gallery>
              {images.map(elem => {
                let mediaWidth = "540";
                let mediaHeight = "720";
                if (!elem.isPortrait) [mediaWidth, mediaHeight] = [mediaHeight, mediaWidth];
                return (
                  <IonCol size="4" key={elem.mediaSrc}>
                    <Item
                      original={elem.mediaSrc}
                      thumbnail={elem.thumbSrc}
                      width={mediaWidth}
                      height={mediaHeight}
                    >
                      {({ ref, open }) => (
                          <ProgressiveImg innerRef={ref as React.MutableRefObject<HTMLImageElement>} src={elem.thumbSrc} placeholderSrc={elem.placeholderSrc} onClick={open} className="smallItemStyles" />
                      )}
                    </Item>
                  </IonCol>
                )
              })}
            </Gallery>

            {videos.map(elem => {
              return (
                <IonCol size="4" key={elem.mediaSrc}>
                  <div className="videoContainer">
                    <video src={elem.mediaSrc} className="smallItemStyles" onClick={() => setMyVideoModal({ isOpen: true, currentVideo: elem.mediaSrc })} />
                    <img src="assets/overlays/playButton.png" alt="play" className="playBtn" onClick={() => setMyVideoModal({ isOpen: true, currentVideo: elem.mediaSrc })} />
                  </div>
                </IonCol>
              )
            })}

          </IonRow>
        </IonGrid>
        <VideoModal isOpen={myVideoModal.isOpen} currentVideo={myVideoModal.currentVideo} onClose={() => setMyVideoModal({ isOpen: false, currentVideo: '' })} />
      </IonContent>
    </IonPage>
  );
};

export default GalleryPage;
