import React, { useEffect, useState } from "react";

import './ProgressiveImg.css';

const ProgressiveImg: React.FC<{
    placeholderSrc: string;
    src: string;
    className: string;
    onClick?: React.MouseEventHandler;
    innerRef?: React.MutableRefObject<HTMLImageElement>;
}> = ({ placeholderSrc, src, ...props }) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImgSrc(src);
        };
    }, [src]);

    const customClass = placeholderSrc && imgSrc === placeholderSrc ? "smallItemStylesLoading" : "smallItemStylesLoaded";

    return (
        <img
            src={imgSrc}
            ref={props.innerRef}
            className={customClass}
            onClick={props.onClick}
        />
    )
}

export default ProgressiveImg;

