import React from "react";

export interface GalleryInterface {
    galleryId: number;
    name: string;
    titleImgSrc: string;
    titleImgPlaceHolderSrc: string;
    media: Media[];
}

export interface Media {
    isVideo: boolean;
    isPortrait: boolean;
    mediaSrc: string;
    thumbSrc: string;
    placeholderSrc: string;
}

interface Context {
    galleries: GalleryInterface[],
}

const GalleriesContext = React.createContext<Context>({
    galleries: [],
});

export default GalleriesContext;