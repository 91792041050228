import { IonModal } from "@ionic/react";
import React from "react";

import './VideoModal.css';

const VideoModal: React.FC<{
    isOpen: boolean;
    currentVideo: string;
    onClose: () => void;
}> = (props) => {

    return (
        <IonModal id="video-modal" isOpen={props.isOpen} onDidDismiss={props.onClose}>
            <div className="wrapper">
                <video src={props.currentVideo} className="videoContent" controls autoPlay />
            </div>
        </IonModal>
    )
}

export default VideoModal;

