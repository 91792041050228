import React, { useState } from "react";

import GalleriesContext, { GalleryInterface } from './galleries-context';

interface Props {
    children?: React.ReactNode;
}

const GalleriesContextProvider: React.FC<Props> = props => {
    const initGalleries = require('../data/data.json');
    
    const [galleries] = useState<GalleryInterface[]>(initGalleries);

    return <GalleriesContext.Provider value={{ galleries }}>
        {props.children}
    </GalleriesContext.Provider>
}

export default GalleriesContextProvider;