import { createGesture, IonCard } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { GalleryInterface } from "../data/galleries-context";

import './TitleImageCard.css';

const TitleImageCard: React.FC<{
    link: string;
    mediaElement: GalleryInterface;
}> = (props) => {
    const cardRef = React.useRef<HTMLIonCardElement>(null);
    const overlayRef = React.useRef<HTMLDivElement>(null);
    const textRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        gestureInit();
    }, []);

    const gestureInit = () => {
        const card = cardRef.current;


        if (card) {
            const gestureX = createGesture({
                el: card,
                direction: 'x',
                gesturePriority: 1,
                gestureName: 'onTouchGestureX',
                onStart: (detail) => {
                    showOverlay(true);
                },
                onEnd: (detail) => {
                    showOverlay(false);
                },
            })
            gestureX.enable();
            const gestureY = createGesture({
                el: card,
                direction: 'y',
                gesturePriority: 0,
                gestureName: 'onTouchGestureY',
                onStart: (detail) => {
                    showOverlay(true);
                },
                onEnd: (detail) => {
                    showOverlay(false);
                },
            })
            gestureY.enable();
        }
    }

    const showOverlay = (show: boolean) => {
        const overlay = overlayRef.current;
        const text = textRef.current;

        if (show) {
            overlay!.style.opacity = '0.8';
            text!.style.opacity = '1';
        } else {
            overlay!.style.opacity = '0';
            text!.style.opacity = '0';
        }
    }

    return (
        <IonCard href={props.link} ref={cardRef} className="portfolioTitleImageCard">
            <div className='portfolioTitleImageContainer'>
                <img src={props.mediaElement.titleImgSrc} className="portfolioTitleImage" alt={props.mediaElement.titleImgSrc} />
                <div className="portfolioTitleImageOverlay" ref={overlayRef}></div>
                <div className="portfolioTitleImageText" ref={textRef}>{props.mediaElement.name}</div>
            </div>
        </IonCard>
    );
}

export default TitleImageCard;

