import { IonCard, IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useContext } from 'react';
import GalleriesContext from '../data/galleries-context';

import './Portfolio.css';
import TitleImageCard from './TitleImageCard';

const Portfolio: React.FC = () => {
  const galleriesContext = useContext(GalleriesContext);

  const portfolioElements = galleriesContext.galleries;

  return (
    <div>
      <IonGrid className="portfolio-grid">
        <IonRow>
          {portfolioElements.map(elem => {
            const link = "/portfolio/gallery/" + elem.galleryId.toString();

            return (
              <IonCol sizeXs="12" sizeLg="6" className="portfolio-col" key={elem.galleryId}>
                <TitleImageCard link={link} mediaElement={elem} />
              </IonCol>
            )
          })}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Portfolio;
