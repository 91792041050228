import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { mailOutline, saveOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import './ContactPage.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const ContactPage: React.FC = () => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);

    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; });

    function onDocumentLoadSuccess({ numPages }: { numPages: number | any }) {
        setNumPages(numPages);
    }

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };

    return (
        <IonPage>
            <IonHeader>

                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton default-href="#"></IonBackButton>
                    </IonButtons>
                    <IonTitle>Contact</IonTitle>
                </IonToolbar>

            </IonHeader>
            <IonContent>
                <IonGrid className="contactGrid">
                    <IonRow className="contaxtGridRow">
                        <IonCol className="contactGridRowPicture"><img src="assets/contact/01024327_m.jpg" alt="Contact" /></IonCol>
                    </IonRow>
                    <IonRow className="contactGridRowText">
                        <IonCol className="contactGridColText">I'm Johanna,<br /><br />Hobby Photographer and Content Creator based in Frankfurt am Main, Germany.<br />I'm living the adventurous life with my little family and my camera always in my bag.<br /><br />We would love to work with you!<br /></IonCol>
                    </IonRow>
                    <IonRow className="contactGridRowIconMail">
                        <IonCol className="contactGridCol"><IonIcon icon={mailOutline} size="large" className="contactIcon" />&nbsp;&nbsp;&nbsp;<a href="mailto:johannawenk@web.de">johannawenk@web.de</a></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div className="Example__container">Media Kit
                                <div className="Example__container__document">
                                    <Document file="assets/pdf/JohannaWenk - Media Kit.pdf" onLoadSuccess={onDocumentLoadSuccess} options={options} loading={<IonSpinner color="primary" />} onLoadError={console.error}>
                                        <Page pageNumber={pageNumber} loading={<IonSpinner color="primary" />}/><Page pageNumber={pageNumber + 1} loading={<IonSpinner color="primary" />} />
                                        <div className="page-controls">
                                            {pageNumber+1 === numPages && 
                                            <button
                                                disabled={pageNumber === 1}
                                                onClick={() => setPageNumber(pageNumber - 2)}>
                                                &lt;
                                            </button>
                                            }
                                            <button><a href="assets/pdf/JohannaWenk - Media Kit.pdf" download="JohannaWenk - Media Kit.pdf"><IonIcon icon={saveOutline} /></a></button>
                                            {pageNumber === 1 && 
                                            <button
                                                disabled={pageNumber === numPages}
                                                onClick={() => setPageNumber(pageNumber + 2)}>
                                                &gt;
                                            </button>
                                            }
                                        </div>
                                    </Document>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default ContactPage;